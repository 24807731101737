import React from 'react';
import loadable from '@loadable/component';
import pMinDelay from 'p-min-delay';
import Loader from './shared/Loader';

// Layouts
import LayoutWithoutSidenav from './shared/layouts/LayoutWithoutSidenav';
import LayoutAdmin from './shared/layouts/LayoutAdmin';

// Lazy load component
const lazy = cb =>
  loadable(() => pMinDelay(cb(), 200), { fallback: <Loader /> });

// ---
// Default application layout

export const DefaultLayout = LayoutWithoutSidenav;

// ---
// Document title template

export const titleTemplate = '%s - Opencampus Events';

// ---
// Routes
//
// Note: By default all routes use { "exact": true }. To change this
// behaviour, pass "exact" option explicitly to the route object

export const defaultRoute = '/';
export const routes = [
  {
    path: '/',
    component: lazy(() => import('./components/frontend/EventsList'))
  },
  {
    path: '/admin/',
    component: lazy(() => import('./components/admin/EventsList')),
    layout: LayoutAdmin
  },
  {
    path: '/admin/events/',
    component: lazy(() => import('./components/admin/EventsList')),
    layout: LayoutAdmin
  },
  {
    path: '/admin/events/new',
    component: lazy(() => import('./components/admin/EventsNew')),
    layout: LayoutAdmin
  },
  {
    path: '/admin/events/:id/edit',
    component: lazy(() => import('./components/admin/EventsEdit')),
    layout: LayoutAdmin
  },
  {
    path: '/admin/organisations/',
    component: lazy(() => import('./components/admin/OrganisationsList')),
    layout: LayoutAdmin
  },
  {
    path: '/admin/organisations/new',
    component: lazy(() => import('./components/admin/OrganisationsNew')),
    layout: LayoutAdmin
  },
  {
    path: '/admin/organisations/:id/edit',
    component: lazy(() => import('./components/admin/OrganisationsEdit')),
    layout: LayoutAdmin
  }
];
