// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cognito_identity_pool_id": "eu-central-1:3b428217-044f-4c68-adb8-ef4c35860e6d",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_4CBcetNSk",
    "aws_user_pools_web_client_id": "1jjjvun6fearucjk2n0tj8go67",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://7m7te4vqefdnbma2o3h4rixepe.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-t7sqsan2kjfznhqvy6pojtz4dq"
};


export default awsmobile;
