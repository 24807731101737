import ReactGA from 'react-ga';

const initAnalytics = () => {
  if (process.env.REACT_APP_ENV === 'production') {
    ReactGA.initialize('UA-106827503-16');
  }
};

const trackPageView = () => {
  if (process.env.REACT_APP_ENV === 'production') {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }
};

export default {
  initAnalytics,
  trackPageView
};
