import React, { useEffect } from 'react';
import Router from './shared/Router';
import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';
import analytics from './lib/analytics';

import './vendor/styles/bootstrap-material.scss';
import './vendor/styles/appwork-material.scss';
import './vendor/styles/theme-paper-material.scss';
import './vendor/styles/colors-material.scss';
import './vendor/styles/uikit.scss';
import './App.scss';

Amplify.configure(awsconfig);

const App = () => {
  useEffect(() => {
    analytics.initAnalytics();
    analytics.trackPageView();
  }, []);
  return <Router />;
};

export default App;
