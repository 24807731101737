import React, { Component } from 'react';
import { connect } from 'react-redux';

class LayoutFooter extends Component {
  render() {
    return (
      <nav className={`layout-footer footer bg-${this.props.footerBg}`}>
        <div className="container-fluid container-p-x pb-3">
          <a
            href="https://edu.opencampus.sh/impressum"
            target="_blank"
            rel="noopener noreferrer"
            className="footer-link pt-3"
          >
            Impressum und Datenschutz
          </a>
        </div>
      </nav>
    );
  }
}

export default connect(store => ({
  footerBg: store.theme.footerBg
}))(LayoutFooter);
