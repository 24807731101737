import React, { Component } from 'react';
import LayoutAdminNavbar from './LayoutAdminNavbar';
import LayoutAdminSidenav from './LayoutAdminSidenav';
import LayoutFooter from './LayoutFooter';
import layoutHelpers from './helpers';
import { withAuthenticator } from 'aws-amplify-react';
import { ToastContainer } from 'react-toastify';
import '../../vendor/libs/react-toastify/react-toastify.scss';

class LayoutHorizontalSidenav extends Component {
  componentDidMount() {
    // Enshure that we have not '.layout-expanded' class on the html element
    layoutHelpers._removeClass('layout-expanded');

    layoutHelpers.init();
    layoutHelpers.update();
    layoutHelpers.setAutoUpdate(true);
  }

  componentWillUnmount() {
    layoutHelpers.destroy();
  }

  render() {
    return (
      <div className="layout-wrapper layout-1 layout-without-sidenav">
        <div className="layout-inner">
          <LayoutAdminNavbar sidenavToggle={false} {...this.props} />

          <div className="layout-container">
            <div className="layout-content">
              <LayoutAdminSidenav orientation="horizontal" {...this.props} />

              <div className="container-fluid flex-grow-1 container-p-y">
                {this.props.children}
              </div>

              <LayoutFooter {...this.props} />
            </div>
          </div>
          <ToastContainer closeButton={<ToastCloseButton />} />
        </div>
      </div>
    );
  }
}

const ToastCloseButton = ({ closeToast }) => (
  <button
    className="Toastify__close-button"
    type="button"
    aria-label="close"
    onClick={closeToast}
  >
    &times;
  </button>
);

export default withAuthenticator(LayoutHorizontalSidenav, {
  signUpConfig: {
    hiddenDefaults: ['phone_number']
  }
});
